<template>
  <section class="page-project-more">
    <div class="page-wrap">
      <div class="hd">
        <img :src="countryObj.icon" />
        <span class="en">{{ countryObj.enName }}</span>
      </div>
      <ul class="list">
        <li class="item" v-for="item in listData" :key="item.id">
          <project-box :project="item"></project-box>
        </li>
      </ul>
    </div>
    <div class="ft">
      <van-pagination
        v-model="page"
        :items-per-page="limit"
        :total-items="totalCount"
        prev-text="Prev"
        next-text="Next"
        mode="simple"
        @change="loadData"
      />
    </div>
  </section>
</template>

<script>
import ProjectBox from "@/components/project/ProjectBox";
import { Pagination as VanPagination } from "vant";

const countryData = {
  thailand: {
    icon:
      "//static.hoolihome.com/global-jwp/assets/img/cultural-tourism/country.png",
    name: "泰国",
    enName: "THAILAND"
  },
  cyprus: {
    icon: "//static.hoolihome.com/global-jwp/assets/img/residence/country1.png",
    name: "塞浦路斯",
    enName: "CYPRUS"
  },
  greece: {
    icon: "//static.hoolihome.com/global-jwp/assets/img/residence/country2.png",
    name: "希腊",
    enName: "GREECE"
  },
  portugal: {
    icon: "//static.hoolihome.com/global-jwp/assets/img/residence/country3.png",
    name: "葡萄牙",
    enName: "PORTUGAL"
  },
  turkey: {
    icon: "//static.hoolihome.com/global-jwp/assets/img/residence/country4.png",
    name: "土耳其",
    enName: "TURKEY"
  },
  japan: {
    icon: "//static.hoolihome.com/global-jwp/assets/img/residence/country5.png",
    name: "日本",
    enName: "JAPAN"
  }
};

export default {
  components: { ProjectBox, VanPagination },
  data() {
    return {
      listData: [],
      totalCount: 0,
      page: 1,
      limit: 10
    };
  },
  computed: {
    countryObj() {
      const { belong } = this.$route.query;
      // belong 项目所属，1=>泰国项目，2=>塞浦路斯项目，3=>希腊项目，4=>葡萄牙项目，5=>土耳其项目，6=>日本项目
      if (parseInt(belong) === 1) {
        return countryData.thailand;
      } else if (parseInt(belong) === 2) {
        return countryData.cyprus;
      } else if (parseInt(belong) === 3) {
        return countryData.greece;
      } else if (parseInt(belong) === 4) {
        return countryData.portugal;
      } else if (parseInt(belong) === 5) {
        return countryData.turkey;
      } else if (parseInt(belong) === 6) {
        return countryData.japan;
      }
      return {};
    }
  },
  created() {
    document.title = `${this.countryObj.enName} Projects`;
    this.loadData();
  },
  methods: {
    loadData() {
      const params = {};
      params.url = this.$apis.jwpList;
      params.data = {
        type: 2,
        page: this.page,
        limit: this.limit,
        belong: this.$route.query.belong
      };
      this.$net.req(params).then(res => {
        if (res) {
          const { data } = res;
          if (data) {
            const { list, pages } = data;
            if (Array.isArray(list)) {
              this.listData = list;
            }
            if (pages) {
              this.totalCount = pages.totalCount;
            }
          }
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.page-project-more {
  padding: 42px 0 62px;
  .page-wrap {
    padding: 20px 0 23px;
    .hd {
      display: flex;
      justify-content: center;
      align-items: center;
      padding-bottom: 22px;
      img {
        width: 20px;
      }
      .en {
        margin: 0 8px 0 12px;
        font-size: 18px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
        line-height: 25px;
      }
    }
    .list {
      display: flex;
      flex-wrap: wrap;
      padding: 0 0 10px 22px;
      .item {
        width: 156px;
        height: 206px;
        margin-bottom: 20px;
        &:nth-child(2n + 2) {
          margin-left: 15px;
        }
      }
    }
  }
  .ft {
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    height: 62px;
    padding: 10px 22px 0;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px -1px 0px 0px rgba(231, 231, 231, 1);
    /deep/ .van-pagination {
      .van-pagination__item {
        flex: inherit;
        width: 80px;
        height: 42px;
        border-radius: 4px;
        border: 1px solid rgba(231, 231, 231, 1);
        font-size: 16px;
        font-weight: 400;
        color: rgba(38, 38, 38, 1);
        &:active {
          background-color: #e7e7e7;
        }
      }
      .van-pagination__page-desc {
        font-size: 14px;
        font-weight: bold;
        color: rgba(38, 38, 38, 1);
      }
    }
  }
}
</style>
